<template>
  <div class="row m-0 justify-content-center">
    <div class="col-12 col-md-11 p-0 my-4 big_text">
      <div class="row justify-content-center align-items-center h-100" >
        <div class="col-12 col-md">
          <div class="row justify-content-md-end justify-content-center align-items-center">
            <div class="col-auto pe-0 gold-text">
              <IconPhone size="size24" />
            </div>
            <div class="col-auto green-text">
              <a :href="'tel:' + headOfficePhone.call" target="_blank">{{headOfficePhone.display}}</a>
            </div>
          </div>
        </div>
        <div class="col-auto h-100 text-center d-none d-md-block">
          <div class="divider"></div>
        </div>
        <div class="col-auto text-center d-md-none w-25 py-2">
          <div class="divider"></div>
        </div>
        <div class="col-12 col-md">
          <div class="row justify-content-md-start justify-content-center align-items-center">
            <div class="col-auto pe-0 gold-text">
              <IconMail size="size24" />
            </div>
            <div class="col-auto green-text">
              <a :href="'mailto:' + headOfficeEmail" target="_blank">{{headOfficeEmail}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    IconPhone: defineAsyncComponent(() => import('@/components/icons/IconPhone.vue')),
    IconMail: defineAsyncComponent(() => import('@/components/icons/IconMail.vue'))
  },
  name: 'BasicContactInfo',
  computed: {
    ...mapGetters([
      'headOffice'
    ]),
    headOfficePhone () {
      const obj = {
        call: '+441254698771',
        display: '+44 (0) 1254 698771'
      }
      if (this.isUK) {
        return obj
      } else {
        if (this.headOffice[0].telephone) {
          var callable = this.headOffice[0].telephone
          callable = callable.replace(/[^\d]/g, '')
          callable = callable.slice(0, 2) + callable.slice(3)
          obj.call = callable
          obj.display = this.headOffice[0].telephone
          return obj
        } else {
          return null
        }
      }
    },
    headOfficeEmail () {
      if (this.isUK) {
        return 'info@alimdaad.co.uk'
      } else {
        if (this.headOffice[0].email) {
          return this.headOffice[0].email
        } else {
          return null
        }
      }
    },
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.divider {
  background-color: #ddd;
  width: 100%;
  height: 1px;
}
.big_text {
  font-size: 1.8rem;
}

@media (min-width: 992px) {
  .divider {
    background-color: #ddd;
    width: 1px;
    height: 100%;
  }
}
</style>
